@mixin lg {
  @media (max-width:#{1920px}) {
    @content;
  }
}

@mixin md {
  @media (max-width:#{1280px}) {
    @content;
  }
}

@mixin sm {
  @media (max-width:#{960px}) {
    @content;
  }
}

@mixin xs {
  @media (max-width:#{600px}) {
    @content;
  }
}
